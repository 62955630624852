<ion-header>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card-header class="ion-text-center">
    <ion-card-title>{{ video.title }}</ion-card-title>
  </ion-card-header>
  <ion-card>
    <!-- <video class="modalvideo" controls poster="{{ basepath+'/storage/app/media/video/'+video.id }}/thumb.png">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/mp4.mp4" type="video/mp4">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/ogg.ogg" type="video/ogg">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/webm.webm" type="video/webm">
    </video> -->
    <iframe texttrack="EN" (load)="iframeLoaded()" id="iframe-video" mozallowfullscreen="true" webkitallowfullscreen="true" allowfullscreen="true" [src]="vimeolink" [width]="vimeowidth()" [height]="vimeoheight()" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
  </ion-card>
  <div class="ajax-request-loader" *ngIf="showAjaxRequestLoader == true">
      <ion-spinner name="crescent" *ngIf="showSpinnerLoader == true"></ion-spinner>
  </div>
</ion-content>
