<div *ngIf="step == 1" class="main-questions">
  
  <ion-row>

    <div class="ion-padding big-thumbs icon-box message-box">
      <app-messagebubble></app-messagebubble>
      <p class="pre_wrap" [innerHTML]="questionnaire.intro"></p>
    </div>
  </ion-row>
  <ion-grid *ngIf="questionnaire.blockquestions">
    <ion-row id="q-{{q.id}}-{{timeStamp}}" class="ion-padding" auto-hide="false" *ngFor="let q of questionnaire.blockquestions; let i = index">
      <ion-col size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col size="12">
        <ion-segment [ngClass]="{
          'segment-danger':q.answer == 1,
          'segment-success':q.answer == 0}"
          [color]="q.answer == 1 ? 'danger' : 'success'" (ionChange)="segmentChanged($event)" [(ngModel)]="q.answer" expand="block">
          <ion-segment-button class="segment-yes" value="1">
            <ion-label>Yes</ion-label>
          </ion-segment-button>
          <ion-segment-button class="segment-no" value="0">
            <ion-label>No</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>
  
  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center" size="12">
        Please submit
      </ion-col>
      <ion-col  class="ion-text-center" size="12">
        <ion-button (click)="next()" [disabled]="button_disabled" color="success">Next</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
<div *ngIf="step == 2" class="extra-questions" color="medium">
  <ion-grid *ngIf="extra">
    <ion-row>
      <div class="ion-padding big-thumbs icon-box message-box extra-questions-head">
        <!-- <app-messagebubble></app-messagebubble> -->
        <div class="pre_wrap" [innerHTML]="extra_message"></div>
      </div>
    </ion-row>
    <ion-row id="qe-{{q.id}}-{{timeStamp}}" class="ion-padding extra-question" auto-hide="false" *ngFor="let q of extra; let i = index">
      <ion-col size="12">
        <p class="ion-padding gub-italic" expand="full" [innerHTML]="q.title"></p>
        <p class="block-extra" expand="full" [innerHTML]="q.extra"></p>
      </ion-col>
      <ion-col size="12">
        <ion-segment scrollable="true" (ionChange)="segmentChanged($event)" [(ngModel)]="q.extraanswer" expand="block">
          <ion-segment-button value="1">
            <ion-label>Yes</ion-label>
          </ion-segment-button>
          <ion-segment-button value="0">
            <ion-label>No</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center" size="12">
        Please submit
      </ion-col>
      <ion-col  class="ion-text-center" size="12">
        <ion-button (click)="back()" color="primary">Back</ion-button>
        <ion-button (click)="submit()" [disabled]="button_disabled" color="success">Submit</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>