import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-smblockquestionnaire',
  templateUrl: './smblockquestionnaire.component.html',
  styleUrls: ['./smblockquestionnaire.component.scss'],
})
export class SmblockquestionnaireComponent implements OnInit {
  @Input() b: any;
  public basepath = '';
  public smpage_id: any;
  public module_id: any;
  public module: any;
  public questionnaire: any;

  constructor(
    public data: DataService,
    public router: Router,
    public route: ActivatedRoute
  ) {
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter in SmblockquestionnaireComponent');
    console.log(this);
    this.basepath = this.data.basePath;
    this.smpage_id = this.route.snapshot.paramMap.get("id");
    this.module_id = this.route.snapshot.paramMap.get("module");
    this.module = [...this.data.smodules, ...this.data.availablesmodules].find(e => e.id && e.id == this.module_id);
    let page = this.module.pages.find(e => e.id == this.smpage_id);
    if (page) {
      let smblock_id = this.route.snapshot.paramMap.get("smblock_id");
      this.questionnaire = page.smblocks.find(e => e.id == smblock_id);
      console.log(this);
    }
  }

  ngOnInit() {
    console.log(' init ngOnInit SmblockquestionnaireComponent');
    console.log(this);
    this.basepath = this.data.basePath;
    this.smpage_id = this.route.snapshot.paramMap.get("id");
    this.module_id = this.route.snapshot.paramMap.get("module");
    this.module = [...this.data.smodules, ...this.data.availablesmodules].find(e => e.id && e.id == this.module_id);
    let page = this.module.pages.find(e => e.id == this.smpage_id);
    if (page) {
      console.log('in PAGE');
      console.log(page);
      let smblock_id = this.route.snapshot.paramMap.get("smblock_id");
      this.questionnaire = page.smblocks.find(e => e.id == smblock_id);
      console.log(this);
    }
  }

  navigate() {
    // if (this.b.layout == 12) {
      // return this.router.navigateByUrl('/smquestionnaire/' + this.b.id);
      return this.router.navigateByUrl('/smquestionnaire/' + this.b.id + '/'+ this.smpage_id +'/'+ this.module_id);
    // }
    
  }

}
