import { Component, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-infozonemodal',
  templateUrl: './infozonemodal.component.html',
  styleUrls: ['./infozonemodal.component.scss'],
})
export class InfozonemodalComponent{

  infozone: any;

  public response:any;
  public basepath:any;
  public redirecturl:any;

  constructor(navParams: NavParams, public modalController: ModalController, public data: DataService) {
  
  }
  
  ngOnInit() {
    console.log("ngOnInit")
    console.log(this)
    console.log(this.infozone)
    
  }

  ionViewWillEnter(){
    this.infozone.text = this.preproccessHtml(this.infozone.text);
  }

  cancel(){
    this.data.stopTTSMedia();
    this.modalController.dismiss();
    this.data.rateApp();
  }

  preproccessHtml(html: string){
    try {
      let srcs = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm).map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
      for(let src of srcs){
        if(src.indexOf('http') == -1){
          html = html.replace(src,this.data.basePath + src.substring(1));
        }
      }
      return html;
    } catch (error){
      return html;
    }
  }
}

