import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { VideomodalComponent } from '../../components/videomodal/videomodal.component';

@Component({
  selector: 'app-smblockvideo',
  templateUrl: './smblockvideo.component.html',
  styleUrls: ['./smblockvideo.component.scss'],
})
export class SmblockvideoComponent implements OnInit {

  @Input() b: any;
  @Input() color: any = 'green';
  public basepath = '';

  ionViewWillEnter() {
    this.basepath = this.data.basePath;
  }
  constructor(
    public data: DataService,
    public router: Router,
    public http: HttpClient,
    public alertController: AlertController,
    public route: ActivatedRoute,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.basepath = this.data.basePath;
  }

  async selectVideo(video){
    let that = this
    const modal = await this.modalController.create({
      component: VideomodalComponent,
      animated: false,
      componentProps: {
        video: video,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open video ' + video.title,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

  videothumbpath(video){
    return encodeURI(this.data.basePath + 'storage/app/media' + video.thumb);
  }

  safeURL(item) {
    return encodeURI(item);
  }
}

