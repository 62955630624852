<div class="ion-padding">
  <ion-button class="big-text-btn" (click)="select()" [color]="b.buttoncolor == 'blue' ? 'primary' : 'success'"
    [innerHTML]="b.title"></ion-button>

  <div>
    <ion-row justify-content-end>
      <ion-col size="3" auto-hide="false">
        <!-- <app-exercisebutton [video]="b.exercise.video"></app-exercisebutton> -->
      </ion-col>
    </ion-row>
  </div>
</div>