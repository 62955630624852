<ion-row>

  <div class="ion-padding big-thumbs icon-box message-box">
    <app-messagebubble></app-messagebubble>
    <p class="pre_wrap" [innerHTML]="questionnaire.intro"></p>
  </div>
</ion-row>
<ion-grid class="ion-padding" *ngIf="questionnaire.numericquestions">

  <div class="question-group" *ngFor="let gr of groups">
    <ion-row [ngClass]="isGroup(q,i) ? 'num-q' : 'num-q-gr'" auto-hide="false"
      *ngFor="let q of gr; let i = index">
      <ion-col *ngIf="!isGroup(q,i)" size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col class="numeric-options ion-text-center" size="12">
        <div class="zone">{{ q.group }}:</div>
        <ion-button [ngClass]="q.answer == option ? 'numeric-active' : ''"
          [color]="q.answer == option ? 'success' : 'primary'" 
          (click)="selectValue(q, option)"
          *ngFor="let option of q.responses">{{ option.label }}</ion-button>
      </ion-col>
    </ion-row>
  </div>

  <ion-row>
    <ion-col class="ion-text-center" size="12">
      Please submit
    </ion-col>
    <ion-col class="ion-text-center" size="12">
      <ion-button (click)="submit()" [disabled]="button_disabled" color="success">Submit</ion-button>
    </ion-col>
  </ion-row>
</ion-grid>