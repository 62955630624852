import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AlertController } from '@ionic/angular';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-homealert',
  templateUrl: './homealert.component.html',
  styleUrls: ['./homealert.component.scss'],
})
export class HomealertComponent implements OnInit {

  public answered_yes: any;
  public navigate: Subscription
  public step = 1;

  constructor(public data: DataService, public router: Router,  public http: HttpClient) {

    //reset action alert box on navigate
    this.navigate = this.router.events.subscribe((event) => {
        if(event instanceof NavigationEnd && event.url == '/home'){
          this.step = 1
        }
    });



   }
  
  ionViewDidEnter(){
    console.log('ionViewDidEnter')
  }

  unlock(){
    console.log("unlock");
    this.data.resetquestionnaire();
  }

  unlockToDashboard(){
    this.data.resetquestionnaire('/home');
  }

  ngOnInit() {
    
    console.log('ngOnInit')
    console.log(this.answered_yes);
    let data = this.data;

    if(data.treatment.lockscreen && data.treatment.lockscreen.questionnaire && data.treatment.lockscreen.questionnaire.blockanswers)
    this.answered_yes = data.treatment.lockscreen.questionnaire.blockanswers.filter((e) => e.answer && !e.extra)


    this.navigate = this.router.events.subscribe((event) => {
      if(event instanceof NavigationStart && event.url == '/home'){
        
        if(data.treatment.lockscreen && data.treatment.lockscreen.questionnaire && data.treatment.lockscreen.questionnaire.blockanswers)
        this.answered_yes = data.treatment.lockscreen.questionnaire.blockanswers.filter((e) => e.answer && !e.extra)
        
      }
  });
  }

}
