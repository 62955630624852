import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-smblocktext',
  templateUrl: './smblocktext.component.html',
  styleUrls: ['./smblocktext.component.scss'],
})
export class SmblocktextComponent implements OnInit {
  @Input() b: any;

  constructor() { }

  ngOnInit() {}

}
