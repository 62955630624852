import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-smblocktitle',
  templateUrl: './smblocktitle.component.html',
  styleUrls: ['./smblocktitle.component.scss'],
})
export class SmblocktitleComponent implements OnInit {

  @Input() b: any;

  constructor(public data: DataService) {
  
  }
  
  ngOnInit() {
    
  }

}
