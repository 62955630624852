<header>
  <nav id="layout-nav" class="navbar navbar-fixed-top navbar-autohide collapsed" role="navigation">
    <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand" target="_parent" href="https://www.getubetter.com/"> </a>
    </div>
    <!-- 
  public menu: any = [
    {
      title: 'Inbox',
      url: '/inbox',
      icon: 'mail',
      color: 'pink'
    },
    {
      title: 'My recovery',
      url: '/myrecoverytimeline',
      icon: 'medkit',
      color: '#EBA301'
    },
    {
      title: 'infoZONE',
      url: '/infozone',
      icon: 'information-circle',
      color: '#AC5199'
    },
    {
      title: 'treatmentZONE',
      url: '/treatmentzone',
      icon: 'body',
      color: '#727DBC'
    },
    {
      title: 'feedbackZone',
      url: '/feedbackzone',
      icon: 'text',
      color: '#0099FF'
    },
    {
      title: 'My GP Practice',
      url: '/mygp',
      icon: 'person',
      color: '#F1E500'
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'options',
      color: '#8DBD21'
    },
    {
      title: 'Help section',
      url: '/helpsection',
      icon: 'help-circle',
      color: '#5AD1D1'
    },
    {
      title: 'Change condition',
      url: '/accesscodes',
      icon: 'medical',
      color: '#808080'
    }
  ]
 -->
    <ul *ngIf="show" class="nav nav-pills">
                                  <li class="item">
              <a routerLink="/home" [innerHTML]="label('Home')">
                  Dashboard
              </a>
                                  <!-- </li>
                                          <li class="item ">
              <a routerLink="/feedbacktreatment" [innerHTML]="label('My treatment')">
                My treatment
              </a>
                                  </li> -->
                                          <li class="item ">
              <a routerLink="/infozone" [innerHTML]="label('infoZONE')">
                Information
              </a>
                                  </li>
                                          <li class="item ">
                                            
              <a routerLink="/treatmentzone" [innerHTML]="label('treatmentZONE')">
                  Local Treatment
              </a>
                                  </li>
                                          <li class="item ">
              <a routerLink="/feedbackzone" [innerHTML]="label('feedbackZONE')">
                  Feedback
              </a>
                                  </li>
      </ul>

    <ul *ngIf="show" class="nav user-nav">
      <li class="item nav-avatar">
        <a class="user_pic" routerLink="/accountdetails" [ngStyle]="{'background-image': 'url(' + data.avatar + ')'}"></a>
      </li>


      <li class="item hide-extra">
        <a routerLink="/home" [innerHTML]="label('Home')">
        Dashboard
        </a>
      </li>
      <li class="item hide-extra ">
        <a routerLink="/feedbacktreatment" [innerHTML]="label('My treatment')">
        My treatment
        </a>
      </li>
      <li class="item hide-extra ">
        <a routerLink="/infozone" [innerHTML]="label('infoZONE')">
        Information
        </a>
      </li>
      <li class="item hide-extra ">
                          
        <a routerLink="/treatmentzone" [innerHTML]="label('treatmentZONE')">
        Local Treatment
        </a>
      </li>
      <li class="item hide-extra ">
        <a routerLink="/feedbackzone" [innerHTML]="label('feedbackZONE')">
        Feedback
        </a>
      </li>

      <li class="item">
        <a (click)="logout()" [innerHTML]="label('Logout')">
          Logout
        </a>
      </li>
      <li class="item ">
        <a routerLink="/inbox" [innerHTML]="label('Inbox')">
          Inbox
        </a>
      </li>
      <li class="item ">
        <a routerLink="/mygp" [innerHTML]="label('My GP Practice')">
          My GP Practice
        </a>
      </li>
      <li class="item ">
        <a routerLink="/settings" [innerHTML]="label('Settings')">
          Settings
        </a>
      </li>
        <li class="item " *ngIf="data.user != undefined && data.user.login_service == 'nhs'">
          <a routerLink="/nhs-callback/settings" [innerHTML]="label('NHS User Settings')">
            NHS User Settings
          </a>
        </li>
      <li class="item ">
        <a routerLink="/helpsection" [innerHTML]="label('Help section')">
          Help section
        </a>
      </li>
      <li class="item " *ngIf="data.appointments != undefined && data.appointments.length > 0">
        <a routerLink="/myappointments/home" [innerHTML]="label('My referrals')">
          My appointments
        </a>
      </li>
      <li class="item ">
        <a routerLink="/accesscodes" [innerHTML]="label('Change condition')">
          Change condition
        </a>
      </li>
        </ul>
    </div>
  </nav>
</header>