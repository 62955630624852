<div class="exercise-item" [ngClass]="exercise.description ? 'with-sub' : 'without-sub'" [ngStyle]="{'background-image': 'url(' + thumbpath() + ')'}" *ngIf="exercise.isstringvideo && video.id && exercise.thumb && exercise.infozone_id == null" (click)="select()">
  <h1 class="ex-title" [innerHTML]=exercise.title>
  
  </h1>
  <div *ngIf="exercise.description" class="ex-subtitle" [innerHTML]=exercise.description>
  
  </div>
  <ion-icon class="diary-icon" name="chevron-forward-outline" slot="end"></ion-icon>

</div>
<div class="exercise-item" [ngClass]="exercise.description ? 'with-sub' : 'without-sub'"  [ngStyle]="{'background-image': 'url(' + thumbpath() + ')'}"  *ngIf="!exercise.isstringvideo && exercise.infozone_id == null" (click)="selectvideo()">
  <h1 class="ex-title" [innerHTML]=exercise.title>
  
  </h1>
  <div *ngIf="exercise.description" class="ex-subtitle" [innerHTML]=exercise.description>
  
  </div>
  <ion-icon class="diary-icon" name="chevron-forward-outline" slot="end"></ion-icon>
</div>

<div class="exercise-item" [ngClass]="exercise.description ? 'with-sub' : 'without-sub'"  [ngStyle]="{'background-image': 'url(' + thumbpath() + ')'}"  *ngIf="exercise.infozone_id != null" (click)="selectinfozone()">
  <h1 class="ex-title" [innerHTML]=exercise.title>
  
  </h1>
  <div *ngIf="exercise.description" class="ex-subtitle" [innerHTML]=exercise.description>
  
  </div>
  <ion-icon class="diary-icon" name="chevron-forward-outline" slot="end"></ion-icon>
</div>

