import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-smblockbutton',
  templateUrl: './smblockbutton.component.html',
  styleUrls: ['./smblockbutton.component.scss'],
})
export class SmblockbuttonComponent implements OnInit {
  @Input() b: any;
  public basepath = '';

  constructor(
    public data: DataService,
    public router: Router,
    public route: ActivatedRoute
  ) {
  }

  ionViewWillEnter() {
    console.log(' will this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  ngOnInit() {
    console.log(' init this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  navigate() {
    if (this.b.layout == 12) {
      this.router.navigateByUrl('/feedbackseekhelpif');
    }
    
    let id = this.route.snapshot.paramMap.get("id");
    let module_id = this.route.snapshot.paramMap.get("module");

    console.log('/smpage/' + this.b.nav_id + '/' + module_id)
    if(this.b.nav_id)
      this.router.navigateByUrl('/smpage/' + this.b.nav_id + '/' + module_id);
  }

}
