<ion-button class="video-thumb custom-thumb" [ngStyle]="{'background-image': 'url(' + thumbpath() + ')'}" *ngIf="video.id && exercise.thumb" (click)="select()">
  <div></div>
</ion-button>

<ion-button class="custom-thumb" *ngIf="!video.thumb && !exercise.thumb" (click)="select()">
  <ion-icon name="videocam"></ion-icon>
</ion-button>
<div></div>
<ion-button class="video-thumb custom-thumb" [ngStyle]="{'background-image': 'url(' + thumbpath() + ')'}" *ngIf="video.id && video.thumb && !exercise.thumb" (click)="select()">
  <div></div>
</ion-button>