<div class="ion-padding">
  <div class="smodules-wrapper">
    <swiper-container id="swiper-container-{{b.id}}" [options]="smodulesOptions" init="false">

      <swiper-slide *ngFor="let sm of slides;">
        <div (click)="navigate2(sm)" class="smodule-box" [ngStyle]="{'background-image': 'url(' + safeURL(basepath+'storage/app/media'+sm.image) + ')'}">
          <div class="smodule-box-title" [innerHTML]="sm.title"></div>
          <ion-icon *ngIf="isboxVisited(sm)" src="/assets/custom-ion-icons/check-mark.svg"></ion-icon>
        </div>
      </swiper-slide>

    </swiper-container>
  </div>
</div>