import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { InfozoneDirective } from './directives/infozone.directive';
import { VideoDirective } from './directives/video.directive';
// import { VideobuttonComponent } from './components/videobutton/videobutton.component';
// import { InfozonebuttonComponent } from './components/infozonebutton/infozonebutton.component';
import { ComponentsModule } from './components/components.module';
import { GlobalErrorHandler, HttpConfigInterceptor } from './services/data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent, 
    InfozoneDirective, 
    VideoDirective
  ],
  imports: [
    BrowserModule, 
    IonicModule.forRoot({
        mode: 'ios',
        innerHTMLTemplatesEnabled: true
    }),
    AppRoutingModule, 
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    provideAnimations(),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
