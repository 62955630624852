import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/services/data.service';
import { InfozonemodalComponent } from 'src/app/components/infozonemodal/infozonemodal.component';

@Component({
  selector: 'app-smblockinfozone',
  templateUrl: './smblockinfozone.component.html',
  styleUrls: ['./smblockinfozone.component.scss'],
})
export class SmblockinfozoneComponent implements OnInit {
  @Input() b: any;
  public basepath = '';

  constructor(
    public data: DataService,
    public router: Router,
    public http: HttpClient,
    public alertController: AlertController,
    public route: ActivatedRoute,
    public modalController: ModalController) { }

  ngOnInit() { 
      this.basepath = this.data.basePath;
  }
  
  async selectInfozone(infozone) {
    let that = this
    const modal = await this.modalController.create({
      component: InfozonemodalComponent,
      animated: false,
      componentProps: {
        infozone: infozone,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open infozone ' + infozone.id,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

  infozonethumbpath(infozone){
    return encodeURI(this.data.basePath + 'storage/app/media' + infozone.thumb);
  }

  safeURL(item) {
    return encodeURI(item);
  }
}