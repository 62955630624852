<ion-header>
  <ion-toolbar>
    <ion-title [innerHTML]="video.title"></ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="cancel()">
        Close
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <!-- <ion-card-header class="ion-text-center">
    <ion-card-title [innerHTML]="video.title"></ion-card-title>
  </ion-card-header> -->
  <ion-card>
    <!-- <video class="modalvideo" controls poster="{{ basepath+'/storage/app/media/video/'+video.id }}/thumb.png">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/mp4.mp4" type="video/mp4">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/ogg.ogg" type="video/ogg">
      <source src="{{ basepath+'/storage/app/media/video/'+video.id }}/webm.webm" type="video/webm">
    </video> -->
    <iframe mozallowfullscreen="true" webkitallowfullscreen="true" allowfullscreen="true" [src]="vimeourl" [width]="vimeowidth()" [height]="vimeoheight()" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    <!-- <div [innerHTML]="exercise.istop"></div>
    <div [innerHTML]="exercise.istail"></div> -->
  </ion-card>
</ion-content>
<ion-footer>
  <div *ngIf="exercise && exercise.type == 'progressive'" class="divider-padding">
    <ion-button *ngIf="!exercise.istail" class="left-button big-text-btn" color="success" (click)="toohard()"> < Easier </ion-button>
    <span class="step-button" [innerHTML]="step"></span>
    <ion-button *ngIf="!exercise.istop" class="right-button big-text-btn" color="danger" (click)="tooeasy()"> Harder > </ion-button>
  </div>

  <div *ngIf="exercise && exercise.type != 'progressive'" class="divider-padding">
    <ion-button *ngIf="!exercise.istail" class="left-button big-text-btn" color="danger" (click)="toohard()">Previous</ion-button>
    <span class="step-button" [innerHTML]="step"></span>
    <ion-button *ngIf="!exercise.istop" class="right-button big-text-btn" color="success" (click)="tooeasy()">Next</ion-button>
  </div>
</ion-footer>