<ion-app  [ngClass]="data.fontSizeClass">
  <!-- <div class="app-environment-notification" *ngIf="environment.server != 'live' && showInfo == true" (click)="toggleInfo()">
    <div>Server: {{data.basePath == 'https://stage.getubetter.com/' ? 'stage' : (data.basePath == 'https://testing.getubetter.com/' ? 'testing' : (data.basePath == 'https://app.getubetter.com/' ? 'live' : (data.basePath == 'https://sandbox.getubetter.com/' ? 'sandbox' : 'dev')))}}</div>
    <div>Url: {{router.url}}</div>
    <div>Event: {{data.gaEvent | json}}</div>
  </div> -->
    <app-desktopnav></app-desktopnav>

    <ion-split-pane contentId="main-content">
        <ion-menu class="side-menu" (click)="closeMenu()" disabled="true" menuId="main" contentId="main-content" type="overlay">
          <ion-content class="ion-padding">
            <ion-list>
                <!-- <ion-item  auto-hide="false" *ngFor="let p of menu" [routerLink]="[p.url]" [hidden]="(data.pathway != undefined && p.url == '/myrecoverytimeline' && data.pathway.show_timeline_page == 0) || (p.url == '/myappointments/home' && data.appointments != undefined && data.appointments.length == 0)">
                  <ion-icon *ngIf="p.src" slot="start" [src]="p.src" ></ion-icon>
                  <ion-icon *ngIf="!p.src" [style.color]="p.color" slot="start" [name]="p.icon" ></ion-icon>
                  <ion-label>
                    <font [innerHTML]="labelsAppMenu(label(p.title))"></font>
                    <b color="primary" *ngIf="p.url == '/inbox' && newitems">({{ newitems }})</b>
                  </ion-label>
                </ion-item> -->

                <ion-item  auto-hide="false" *ngFor="let p of menu" [routerLink]="[p.url]" [hidden]="(data.pathway != undefined && p.url == '/myrecoverytimeline' && data.pathway.show_timeline_page == 0) || (p.url == '/myappointments/home' && data.appointments != undefined && data.appointments.length == 0) || (p.url == '/nhs-callback/settings' && (data.user == undefined || data.user.login_service != 'nhs'))">
                  <ion-icon *ngIf="p.src" slot="start" [src]="p.src" ></ion-icon>
                  <ion-icon *ngIf="!p.src" [style.color]="p.color" slot="start" [name]="p.icon" ></ion-icon>
                  <ion-label>
                    <font [innerHTML]="labelsAppMenu(label(p.title))"></font>
                    <b color="primary" *ngIf="p.url == '/inbox' && newitems">({{ newitems }})</b>
                  </ion-label>
                </ion-item>
                <ion-item (click)="logout()">
                  <ion-icon src="/assets/custom-ion-icons/menu-logout.svg" slot="start" ></ion-icon>
                  <ion-label [innerHTML]="label('Logout')">
                  </ion-label>
                </ion-item>
              </ion-list>

          </ion-content>
        </ion-menu>
        <ion-router-outlet id="main-content" [swipeGesture]="false"></ion-router-outlet>
      </ion-split-pane>
      <div class="maintenance-mode" *ngIf="data.maintenance_mode == true">
        <article>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 202.24"><defs><style>.cls-1{fill:#fff;}</style></defs><title>Asset 3</title><g id="Layer_2" data-name="Layer 2"><g id="Capa_1" data-name="Capa 1"><path class="cls-1" d="M101.12,0A101.12,101.12,0,1,0,202.24,101.12,101.12,101.12,0,0,0,101.12,0ZM159,148.76H43.28a11.57,11.57,0,0,1-10-17.34L91.09,31.16a11.57,11.57,0,0,1,20.06,0L169,131.43a11.57,11.57,0,0,1-10,17.34Z"/><path class="cls-1" d="M101.12,36.93h0L43.27,137.21H159L101.13,36.94Zm0,88.7a7.71,7.71,0,1,1,7.71-7.71A7.71,7.71,0,0,1,101.12,125.63Zm7.71-50.13a7.56,7.56,0,0,1-.11,1.3l-3.8,22.49a3.86,3.86,0,0,1-7.61,0l-3.8-22.49a8,8,0,0,1-.11-1.3,7.71,7.71,0,1,1,15.43,0Z"/></g></g></svg>
          <h1>We&rsquo;ll be back soon!</h1>
          <div>
              <p>Sorry for the inconvenience. We&rsquo;re performing some maintenance at the moment. We&rsquo;ll be back up shortly.</p>
              <p>The getUBetter Team</p>
          </div>
        </article>
      </div>
      <div class="ajax-request-loader" *ngIf="data.showAjaxRequestLoader == true && data.networkConnection != undefined &&  data.networkConnection.connected == true">
          <ion-spinner name="crescent" *ngIf="data.showSpinnerLoader == true"></ion-spinner>
      </div>
      <div class="ajax-request-loader" *ngIf="data.networkConnection != undefined &&  data.networkConnection.connected == false" (click)="data.presentAlert('Your network is unavailable','','OK','Please check your Wi-Fi or data connection.');"></div>
</ion-app>