import { Directive } from '@angular/core';

@Directive({
  selector: '[appInfozone]'
})
export class InfozoneDirective {

  constructor() { }

}
