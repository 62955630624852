import { ChangeDetectorRef, Component, NgZone } from '@angular/core';

import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser as CapacitorBrowser } from '@capacitor/browser';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public environment;
  public showInfo = true;
  constructor(
    public router: Router,
    public data: DataService,
    public platform: Platform,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone
  ) {
    // localStorage.removeItem('pft_wizard');
    this.environment = environment;
  }

  public menu: any = [
    {
      title: 'Dashboard',
      url: '/home',
      icon: 'home',
      color: 'grey',
      src: '/assets/custom-ion-icons/menu-dashboard.svg',
    },
    {
      title: 'Inbox',
      url: '/inbox',
      icon: 'mail',
      color: 'pink',
      src: '/assets/custom-ion-icons/menu-letters.svg',
    },
    {
      title: 'My recovery',
      url: '/myrecoverytimeline',
      icon: 'medkit',
      src: '/assets/custom-ion-icons/menu-recovery.svg',
      color: '#EBA301'
    },
    {
      title: 'infoZONE',
      url: '/infozone',
      icon: 'information-circle',
      color: '#AC5199',
      src: '/assets/custom-ion-icons/menu-infozones.svg'
    },
    {
      title: 'treatmentZONE',
      url: '/treatmentzone',
      icon: 'body',
      color: '#727DBC',
      src: '/assets/custom-ion-icons/menu-treatment.svg'
    },
    {
      title: 'My referrals',
      url: '/myappointments/home',
      icon: 'list',
      color: '#cecece',
      src: '/assets/custom-ion-icons/menu-appointments.svg'
    },
    {
      title: 'feedbackZONE',
      url: '/feedbackzone',
      icon: 'chatbubble-ellipses',
      color: '#0099FF',
      src: '/assets/custom-ion-icons/menu-feedback.svg'
    },
    {
      title: 'My GP Practice',
      url: '/mygp',
      icon: 'person',
      color: '#F1E500',
      src: '/assets/custom-ion-icons/menu-mygp.svg'
    },
    {
      title: 'Settings',
      url: '/settings',
      icon: 'options',
      color: '#8DBD21',
      src: '/assets/custom-ion-icons/menu-settings.svg'
    },
    {
      title: "NHS settings",
      url: "/nhs-callback/settings",
      icon: "options",
      color: "#FF0000",
      src: '/assets/custom-ion-icons/nhs-settings-icon.svg'
    },
    {
      title: 'Help section',
      url: '/helpsection',
      icon: 'help-circle',
      color: '#5AD1D1',
      src: '/assets/custom-ion-icons/menu-help.svg',
    },
    {
      title: 'Change condition',
      url: '/accesscodes',
      icon: 'medical',
      color: '#808080',
      src: '/assets/custom-ion-icons/menu-conditions.svg'
    }
  ]

  ngOnInit() {
    // console.log('ngOnInit progress')
    // console.log(this.data)
    // console.log(this.data.progress)
    // if(this.data.user && !this.data.progress)
    //   this.router.navigateByUrl('/recoverytimeline')
    this.initializeApp();
  }

  get code() {
    //if there is no progress setup in this screen then go to setup flow
    if (!this.data.progress)
      this.router.navigateByUrl('/recoverytimeline')

    return this.data.progress.code
  }

  logout() {
    this.data.logout();
  }

  get newitems(): string {
    return this.data.letters ? this.data.letters.filter((e) => !e.read).length : 0
  }

  label(name): string {
    if (this.data.menu) {
      let found = this.data.menu.find((e) => e.name == name)
      if (found && found.custom)
        return found.custom
    }

    return name
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.platform.backButton.subscribeWithPriority(99999, (processNextHandler) => {
        return;
        console.log('back button pressed');
        if (this.router.routerState.snapshot.url == '/feedbackinput') {
          let feedbackinputvisited = this.data.routerHistory.indexOf('/feedbackinput');
          if (feedbackinputvisited > -1 && this.data.routerHistory[feedbackinputvisited - 1] != undefined && this.data.routerHistory[feedbackinputvisited - 1] == '/welcomequestions') {
            this.data.reinjured();
            console.log('back to injured!!!!!!!');
            return;
          }
        }
        // let splashVisited = ['/welcomequestions','/feedbackinput','/recoverytimeline'].indexOf(this.data.routerHistory[this.data.routerHistory.length - 1]);
        // console.log('splash visited: ',splashVisited);
        // console.log('current link: ',this.router.routerState.snapshot.url);
        // if(splashVisited > -1 && this.data.routerHistory[this.data.routerHistory.length - 2] == '/splash'){
        //   window.history.go(-2);
        //   return;
        // }
        processNextHandler();
      });

      console.log('!!!!!!! base path: ', this.data.basePath);

      Network.getStatus().then(($result) => {
        console.log('networkConnection: ', $result);
        this.data.networkConnection = $result;
        if (this.data.networkConnection.connected == false) {
          this.data.presentAlert('Your network is unavailable', '', 'OK', 'Please check your Wi-Fi or data connection.');
        }
      })

      Network.addListener('networkStatusChange', status => {
        if (this.data.networkConnection != undefined) {
          if (this.data.networkConnection.connected == true && status.connected == false) {
            this.data.presentAlert('Your network is unavailable', '', 'OK', 'Please check your Wi-Fi or data connection.');
          }
        } else if (status.connected == false) {
          this.data.presentAlert('Your network is unavailable', '', 'OK', 'Please check your Wi-Fi or data connection.');
        }
        this.data.networkConnection = status;
      });

      App.addListener('appStateChange', ({ isActive }) => {

        if (!isActive) {

          if (this.data.ttsMediaPlayer != undefined) {
            this.data.ttsMediaPlayer.pause();
            this.data.ttsMediaPlayer.currentTime = 0;
            for (let i in this.data.ttsMediaPlayerSources) {
              this.data.ttsMediaPlayerSources[i] = '';
            }
            this.changeDetectorRef.detectChanges();
          }
        } else {
          // if we are home and came back from /nhs/settings
          // if(this.router.url == '/home' && this.data.visitedNhsSettingsPage){
          //   // this.data.nhsSettings = false;
          //   this.router.navigateByUrl('/nhs-callback/settings');
          // }
          if (['/accesscodes', '/splash', '/welcomequestions', '/welcomequestionnaire', '/feedbackinput', '/recoverytimeline'].indexOf(this.router.url) == -1 && this.data.isUserLogged == true && this.data.pathway != undefined && this.data.pathway && this.data.day && (this.data.last_progress == undefined || !this.data.last_progress || new Date(this.data.last_progress.created_at).getDate() != new Date().getDate())) {
            if (this.data.pathway.show_traffic_light_page == 0) {
              this.data.updateAsymptomaticPathwayProgress();
            } else {
              if (this.data.pathway.show_timeline_page == 1) {
                this.router.navigateByUrl('/recoverytimeline', { replaceUrl: true });
              } else {
                if (this.data.pathway.show_traffic_light_page == 1) {
                  this.router.navigateByUrl('/feedbackinput', { replaceUrl: true });
                }
              }
            }
          }
        }
      });

      // see the route changing in console.log()

      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          // console.log('App Url Open Event: ', event); // Debugging
          let slug: any;
          if (this.data.diag.platform == 'ios') {
            slug = event.url.replace('gub://', '');
          } else {
            slug = event.url.replace('app://com.getubetter.gubapp/', '');
          }
          console.log('App Url Open Event Slug: ', slug); // Debugging
          if (slug) {
            this.router.navigateByUrl(slug);
          } else {
            this.router.navigate['/login'];
          }

          CapacitorBrowser.close();
        });
      });
    });
  }

  closeMenu() {
    this.data.closeMenu()
  }

  labelsAppMenu(input: any) {
    return this.data.labels(input, "AppMenu");
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;
    setTimeout(() => {
      this.showInfo = !this.showInfo;
    }, 5000)
  }

}
