import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot } from "@angular/router";
import { DataService } from './data.service';

@Injectable({
  providedIn: "root"
})
export class HomeguardService   {
  constructor(public router: Router, public data: DataService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    //if there is no progress setup in this screen then go to setup flow
    if(this.data.progress == undefined || this.data.progress.code == undefined){
      this.router.navigateByUrl('/recoverytimeline')
      return false
    }

    return true;
  }
}