<ion-header>
  <ion-toolbar>
		<ion-buttons slot="end">
			<ion-button (click)="cancel()">
				Close
			</ion-button>
		</ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="data.fontSizeClass">
  <ion-card>
    <ion-card-header>
      <div class="position-relative">
        <app-messagebubble [text]="infozone.title + infozone.text" *ngIf="data.disable_touch_to_speak == '0' && data.user.language_id > 0"></app-messagebubble>
        <ion-card-title>{{ infozone.title }}</ion-card-title>
        <div><img [src]="basepath+'storage/app/media'+infozone.image" alt=""></div>
      </div>      
    </ion-card-header>
    <ion-card-content class="custom-html" >
      <div [innerHTML]="infozone.text | safeHtml"></div>
    </ion-card-content>
  </ion-card>
</ion-content>
