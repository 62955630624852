import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

import { ModalController } from '@ionic/angular';
import { ExercisemodalComponent } from '../../components/exercisemodal/exercisemodal.component'

@Component({
  selector: 'app-smblockexercise',
  templateUrl: './smblockexercise.component.html',
  styleUrls: ['./smblockexercise.component.scss'],
})
export class SmblockexerciseComponent implements OnInit {
  @Input() b: any;
  public basepath = '';

  constructor(
    public data: DataService,
    public router: Router,
    public route: ActivatedRoute,
    public modalController: ModalController
  ) {
  }

  ionViewWillEnter() {
    console.log(' will this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
  }

  ngOnInit() {
    console.log(' init this.data.basePath');
    console.log(this.data.basePath);
    this.basepath = this.data.basePath;
    this.data.exerciseLevel.addListener('changed', (obj) => {
      console.log("changed")
      console.log(obj);
      if(obj){
        this.b.exercise = obj;
      }
    })
  }

  navigate() {
    console.log(this.b.exercise);
    // if (this.b.layout == 12) {
    //   return this.router.navigateByUrl('/feedbackseekhelpif');
    // }
    
    // let id = this.route.snapshot.paramMap.get("id");
    // let module_id = this.route.snapshot.paramMap.get("module");

    // console.log('/smpage/' + this.b.nav_id + '/' + module_id)
    // if(this.b.nav_id)
    //   this.router.navigateByUrl('/smpage/' + this.b.nav_id + '/' + module_id);
  }


  async select(){
    let that = this
    console.log(that.b);
    const modal = await this.modalController.create({
      component: ExercisemodalComponent,
      animated: false,
      componentProps: {
        video: that.b.exercise.video,
        exercise: that.b.exercise,
        reseter: this.reseter,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open Exercise '+this.b.id,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

   thumbpath(){
    if(this.b.exercise.thumb)
      return encodeURI(this.data.basePath + 'storage/app/media' + this.b.exercise.thumb);
    else
      return encodeURI(this.data.basePath + 'storage/app/media' + this.b.exercise.video.thumb);
   }

  reseter(exercise){
    console.log("reseter");
    // console.log(this.exercise.current.id+'-1');
    // this.b.exercise = exercise;
    // console.log(this.exercise.current.id+'-2');
    this.data.exerciseLevel.emit("refresh_blocks",true);
  }

}
