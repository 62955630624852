<ion-row>
    <!-- <div id="drMacText" >
      <div id="drMacPic"></div>
      <div class="home-message" class="pre-wrap" [innerHTML]="questionnaire.intro"></div>
    </div> -->

    <div class="ion-padding big-thumbs icon-box gub-italic message-box">
      <app-messagebubble></app-messagebubble>
      <p class="pre_wrap" [innerHTML]="questionnaire.intro"></p>
    </div>
  </ion-row>
  <ion-grid *ngIf="questionnaire.numericquestions">
    <!-- <ion-row class="ion-padding" auto-hide="false" *ngFor="let q of questionnaire.numericquestions">
      <ion-col size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col size="12">
        <ion-segment (ionChange)="segmentChanged($event)" [(ngModel)]="q.answer" expand="block">
          <ion-segment-button [value]="option" *ngFor="let option of q.responses">
            <ion-label>{{ option.label }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row> -->

    <ion-row class="ion-padding" auto-hide="false" *ngFor="let q of questionnaire.numericquestions">
      <ion-col size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col class="numeric-options ion-text-center" size="12">
          <ion-button [ngClass]="q.answer == option ? 'numeric-active' : ''" [color]="q.answer == option ? 'success' : 'primary'" (click)="selectValue(q, option)" *ngFor="let option of q.responses">{{ option.label }}</ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col class="ion-text-center" size="12">
        Please submit
      </ion-col>
      <ion-col class="ion-text-center" size="12">
        <ion-button (click)="submit()" [disabled]="button_disabled" color="success">Submit</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>