<div *ngIf="data.treatment.lockscreen">
  <ion-card *ngIf="data.treatment.lockscreen.questionnaire.questionnaire.type == 'block'" class="ion-padding black-text">
    <div *ngIf="step == 1" class="step1">
      <h1 class="title-button" expand="full" color="success">Important</h1>  
      <div class="ion-padding big-thumbs icon-box" >
        <app-messagebubble></app-messagebubble>
        <p class="pre_wrap">Hi {{data.user.name}}, <br/>Previously you answered <strong>‘YES’</strong> to the following question(s):</p>
      </div>
      <ul>
        <li *ngFor="let q of answered_yes">
          <ion-label [innerHTML]="q.question.title"></ion-label>
        </li>
      </ul>

    <p [innerHTML]="data.treatment.lockscreen.output.question.trim().toLowerCase() === 'false' ? 'We advised you to' : data.treatment.lockscreen.output.question"></p>
            <!-- <p [innerHTML]="data.treatment.lockscreen.output.question"></p> -->

      <div>To access the app, we need to make sure you are safe to self-manage.<br /><br />
        Press <strong>‘YES’</strong> if you had a clinical review OR you are no longer worried about your problem. This will give you access to all the content in the app which can help with your recovery.<br /><br />
        Press <strong>‘NO’</strong> if you have NOT seen anyone. You will have restricted access to the app until you get a clinical review.<br /><br />
      </div>
      <ion-row>
          <ion-col class="ion-text-center" size="12">
            <ion-button (click)="unlock()" expand="block" color="success">Yes</ion-button>
            <ion-button (click)="step = 2; data.setEventsAnalytics('No Still not Seen Clinician','event', {product_zone: 'Screening and symptom checker', questionnaire_id: data.treatment.lockscreen.questionnaire.questionnaire.id})" expand="block" color="danger">No</ion-button>
          </ion-col>
      </ion-row>
    </div>
    <div *ngIf="step == 2" class="step2">
      <h1 class="title-button" expand="full" color="success">Safety message</h1>  
      <div class="ion-padding big-thumbs icon-box gub-italic home-box message-box" >
        <app-messagebubble></app-messagebubble>
        <p class="pre_wrap">Hi {{data.user.name}}, <br/>Your safety is important to us.​</p>
        <div class="custom-html"[innerHTML]="data.boxes.lockscreen.lock.text"></div>
      </div>
      <ion-row>
          <ion-col class="ion-text-center" size="12">
            <ion-button (click)="step = 1" expand="block" color="success">OK</ion-button>
          </ion-col>
      </ion-row>
    </div>
  </ion-card>
  <ion-card class="ion-padding" *ngIf="data.treatment.lockscreen.questionnaire.questionnaire.type == 'numeric'" >
      <div id="drMacText">
          <div id="drMacPic"></div>
          <div id="drMacUserPic"></div>
          <div class="home-message" class="pre-wrap" [innerHTML]="data.boxes.lockscreen.lock.text"></div>
      </div>
      <ion-row>
          <ion-col size="12">
              <ion-label>Have your symptoms resolved:</ion-label>
          </ion-col>
          <ion-col class="ion-text-center" size="12">
              <ion-button routerLink="/questionnaire" expand="block" color="success">Yes</ion-button>
              <ion-button (click)="step = 2" expand="block" color="danger">No</ion-button>
          </ion-col>
      </ion-row>
  </ion-card>
</div>
