<ion-grid *ngIf="questionnaire.blockquestions">
    <ion-row class="ion-padding" auto-hide="false" *ngFor="let q of questionnaire.blockquestions">
      <ion-col size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col size="12">
        <ion-segment (ionChange)="segmentChanged($event)" [(ngModel)]="q.answer" expand="block">
          <ion-segment-button value="1">
            <ion-label>Yes</ion-label>
          </ion-segment-button>
          <ion-segment-button value="0">
            <ion-label>No</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid *ngIf="questionnaire.numericquestions">
    <ion-row class="ion-padding" auto-hide="false" *ngFor="let q of questionnaire.numericquestions">
      <ion-col size="12">
        <p expand="full" [innerHTML]="q.title"></p>
      </ion-col>
      <ion-col size="12">
        <ion-segment [(ngModel)]="q.answer" expand="block">
          <ion-segment-button [value]="response.id" *ngFor="let response of q.responses">
            <ion-label [innerHTML]="response.label"></ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid>
    <ion-row>
      <ion-col class="ion-text-center" size="12">
        Please submit
      </ion-col>
      <ion-col  class="ion-text-center" size="12">
        <ion-button (click)="submit()" color="success">OK</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>