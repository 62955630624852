import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalController } from '@ionic/angular';
import { InfozonemodalComponent } from '../../components/infozonemodal/infozonemodal.component'

@Component({
  selector: 'app-infozonebutton',
  templateUrl: './infozonebutton.component.html',
  styleUrls: ['./infozonebutton.component.scss'],
})
export class InfozonebuttonComponent implements OnInit {
  @Input() infozone: any;

  constructor(public modalController:ModalController, public data: DataService) { }

  ngOnInit() {}

  async select(){
    let that = this
    const modal = await this.modalController.create({
      component: InfozonemodalComponent,
      animated: false,
      componentProps: {
        infozone: that.infozone,
        basepath: that.data.basePath
      }
    });
    return await modal.present();
  }

  get thumbpath(){
    return encodeURI(this.data.basePath + 'storage/app/media' + this.infozone.thumb);
  }
  
}