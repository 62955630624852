import { Device, DeviceId } from "@capacitor/device";

// mfa.service.ts
interface MultiFactorAuth {
  phone?: string;
  email?: string;
  otpCodeSentTime?: Date;
  otpExpiryTime?: Date;
  maskedPhone?: string;
  maskedEmail?: string;
  mfaFlow?: 'register' | 'login'; // Indicates the current MFA flow
}

export class MfaService implements MultiFactorAuth {
  // Implementing the properties directly
  phone?: string;
  email?: string;
  otpCodeSentTime?: Date;
  otpHasExpired?: boolean;
  otpExpiryTime?: Date;
  maskedPhone?: string;
  maskedEmail?: string;
  mfaFlow?: 'register' | 'login';
  loginResponse?: any = null;
  private static instance: MfaService;

  public otpcode_exp_time: number = 10 * 60 * 1000; // 10 minutes in milliseconds
  public otpResendDelay: number = 5 * 1000; // 5 seconds

  private constructor() { }
  
  public static getInstance(): MfaService {
    if (!MfaService.instance) {
      MfaService.instance = new MfaService();
    }
    return MfaService.instance;
  }

  get mfaAuthValidated(): boolean {
    const storedValue = localStorage.getItem('mfaAuthValidated');

    if (storedValue !== null) {
      return JSON.parse(storedValue);
    } else {
      // Implement API call if necessary
      return false;
    }
  }

  set mfaAuthValidated(value: boolean) {
    localStorage.setItem('mfaAuthValidated', JSON.stringify(value));
  }

  mfaNow(flow: 'register' | 'login' | '' = '') {
    const currentTime = new Date();
    this.otpCodeSentTime = currentTime;
    this.otpExpiryTime = new Date(currentTime.getTime() + this.otpcode_exp_time);
    this.otpHasExpired = false;

    setTimeout(() => {
      this.otpHasExpired = true;
    }, this.otpcode_exp_time);

    if (flow !== '') {
      this.mfaFlow = flow;
    }
  }

  mfaInvalidate() {
    localStorage.removeItem('mfaAuthValidated');
    // Implement API call to invalidate MFA if necessary
  }

  mfaValidate(val: boolean = true) {
    localStorage.setItem('mfaAuthValidated', JSON.stringify(val));
  }

  checkApiIfDeviceIsMfaAuthenticated() {
    // Implement API call to check if device is MFA authenticated
    const deviceId = MfaService.deviceId();
    const lastChecked = localStorage.getItem('mfaLastChecked');
    const oneWeek = 7 * 24 * 60 * 60 * 1000;

    const currentTime = new Date().getTime();

    if (!lastChecked || currentTime - Number(lastChecked) > oneWeek) {
      fetch('api/mfa/isauth', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ deviceId })
      })
      .then(response => response.json())
      .then(data => {
        this.mfaAuthValidated = data.isAuthenticated;
        localStorage.setItem('mfaLastChecked', currentTime.toString());
      })
      .catch(() => {
        // Handle error
      });
    }

  }

  static deviceId() {
    let deviceId = localStorage.getItem('deviceId');
    if (deviceId) {
      return deviceId;
    } else {

      Device.getId().then((info: DeviceId) => {
        localStorage.setItem('deviceId', info.identifier);
        deviceId = info.identifier;
      }).catch(() => {
        // Fallback if Device.getId() fails
        const fallbackId = 'web-' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('deviceId', fallbackId);
        deviceId = fallbackId;
      });

      if (!deviceId) {
        // Fallback if Device.getId() fails
        const fallbackId = 'web-' + Math.random().toString(36).substr(2, 9);
        localStorage.setItem('deviceId', fallbackId);
        deviceId = fallbackId;
      }
    }

    return deviceId;
  }

}