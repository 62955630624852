<div (click)="selectVideo(b.video)"  class="ion-padding">

  <img *ngIf="!b.image && b.video"[src]="videothumbpath(b.video)" alt="">

  <div *ngIf="b.image" class="smtileitem-wrapper"
    [ngStyle]="{'background-image': 'url(' + safeURL(basepath+'storage/app/media'+b.image) + ')'}">
    <h1 class="title-button" expand="full" >
      <ion-icon name="play-circle-sharp"></ion-icon>
    </h1>
  </div>
</div>