import { Directive } from '@angular/core';

@Directive({
  selector: '[appVideo]'
})
export class VideoDirective {

  constructor() { }

}
