import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ModalController } from '@ionic/angular';
import { VideomodalComponent } from '../../components/videomodal/videomodal.component'

@Component({
  selector: 'app-videobutton',
  templateUrl: './videobutton.component.html',
  styleUrls: ['./videobutton.component.scss'],
})
export class VideobuttonComponent implements OnInit {
  @Input() video: any;

  constructor(public modalController:ModalController, public data: DataService) { }

  ngOnInit() {}

  select_(){
    console.log(this.video)
  }

  async select(){
    let that = this
    const modal = await this.modalController.create({
      component: VideomodalComponent,
      animated: false,
      componentProps: {
        video: that.video,
        basepath: that.data.basePath
      }
    });

    let payload = {
      token: this.data.token,
      title: 'Open video '+this.video.title,
      pathway_id: that.data.pathway ? that.data.pathway.id : '',
      condition: (that.data.pathway && that.data.pathway.condition) ? that.data.pathway.condition.id : ''
    };
    this.data.apiPost(this.data.basePath + 'api/userappnav', this.data.payloadMiddleware(payload)).subscribe({
      next: function(response:any) {
        // console.log(response);
      },
      error: function(response) {
        // console.log(arguments);
      }
    })

    return await modal.present();
  }

  get thumbpath(){
    return encodeURI(this.data.basePath + 'storage/app/media' + this.video.thumb);
  }
}