import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeguardService } from './services/homeguard.service';

const routes: Routes = [
  { path: "", redirectTo: "fakesplash", pathMatch: "full" },
  {
    path: "splash",
    loadChildren: () =>
      import("./pages/splash/splash.module").then((x) => x.SplashPageModule),
  },
  {
    canActivate: [HomeguardService],
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((x) => x.HomePageModule),
  },
  {
    path: "accountdetails",
    loadChildren: () =>
      import("./pages/accountdetails/accountdetails.module").then(
        (x) => x.AccountdetailsPageModule
      ),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./pages/admin/admin.module").then((x) => x.AdminPageModule),
  },
  {
    path: "changeday",
    loadChildren: () =>
      import("./pages/changeday/changeday.module").then(
        (x) => x.ChangedayPageModule
      ),
  },
  {
    path: "termsandconditions",
    loadChildren: () =>
      import("./pages/termsandconditions/termsandconditions.module").then(
        (x) => x.TermsandconditionsPageModule
      ),
  },
  {
    path: "changerecovery",
    loadChildren: () =>
      import("./pages/changerecovery/changerecovery.module").then(
        (x) => x.ChangerecoveryPageModule
      ),
  },
  {
    path: "rewatchvideo",
    loadChildren: () =>
      import("./pages/rewatchvideo/rewatchvideo.module").then(
        (x) => x.RewatchvideoPageModule
      ),
  },
  {
    path: "welcomevideo",
    loadChildren: () =>
      import("./pages/welcomevideo/welcomevideo.module").then(
        (x) => x.WelcomevideoPageModule
      ),
  },
  {
    path: "welcomequestions",
    loadChildren: () =>
      import("./pages/welcomequestions/welcomequestions.module").then(
        (x) => x.WelcomequestionsPageModule
      )
  },
  {
    path: "welcomequestionnaire",
    loadChildren: () =>
      import("./pages/welcomequestionnaire/welcomequestionnaire.module").then(
        (x) => x.WelcomequestionnairePageModule
      )
  },
  {
    path: "helpmenu",
    loadChildren: () =>
      import("./pages/helpmenu/helpmenu.module").then(
        (x) => x.HelpmenuPageModule
      )
  },
  {
    path: "helpsection",
    loadChildren: () =>
      import("./pages/helpsection/helpsection.module").then(
        (x) => x.HelpsectionPageModule
      ),
  },
  {
    path: "tcmissing",
    loadChildren: () =>
      import("./pages/tcmissing/tcmissing.module").then(
        (x) => x.TcmissingPageModule
      ),
  },
  {
    path: "noworrying",
    loadChildren: () =>
      import("./pages/noworrying/noworrying.module").then(
        (x) => x.NoworryingPageModule
      ),
  },
  {
    path: "lock",
    loadChildren: () =>
      import("./pages/lock/lock.module").then((x) => x.LockPageModule),
  },
  {
    path: "prelock",
    loadChildren: () =>
      import("./pages/prelock/prelock.module").then((x) => x.PrelockPageModule),
  },
  {
    path: "recoveryadvise",
    loadChildren: () =>
      import("./pages/recoveryadvise/recoveryadvise.module").then(
        (x) => x.RecoveryadvisePageModule
      ),
  },
  {
    path: "adviselock",
    loadChildren: () =>
      import("./pages/adviselock/adviselock.module").then(
        (x) => x.AdviselockPageModule
      ),
  },
  {
    path: "mainmenu",
    loadChildren: () =>
      import("./pages/mainmenu/mainmenu.module").then(
        (x) => x.MainmenuPageModule
      ),
  },
  {
    path: "recoverytimeline",
    loadChildren: () =>
      import("./pages/recoverytimeline/recoverytimeline.module").then(
        (x) => x.RecoverytimelinePageModule
      ),
  },
  {
    path: "inbox",
    loadChildren: () =>
      import("./pages/inbox/inbox.module").then((x) => x.InboxPageModule),
  },
  {
    path: "loading",
    loadChildren: () =>
      import("./pages/loading/loading.module").then((x) => x.LoadingPageModule),
  },
  {
    path: "voucher",
    loadChildren: () =>
      import("./pages/voucher/voucher.module").then((x) => x.VoucherPageModule),
  },
  {
    path: "message/:id",
    loadChildren: () =>
      import("./pages/message/message.module").then((x) => x.MessagePageModule),
  },
  {
    path: "gateway/:id",
    loadChildren: () =>
      import("./pages/gateway/gateway.module").then((x) => x.GatewayPageModule),
  },
  {
    path: "feedbackinput",
    loadChildren: () =>
      import("./pages/feedbackinput/feedbackinput.module").then(
        (x) => x.FeedbackinputPageModule
      ),
  },
  {
    path: "feedbacktreatment",
    loadChildren: () =>
      import("./pages/feedbacktreatment/feedbacktreatment.module").then(
        (x) => x.FeedbacktreatmentPageModule
      ),
  },
  {
    path: "preventionplan",
    loadChildren: () =>
      import("./pages/preventionplan/preventionplan.module").then(
        (x) => x.PreventionPlanPageModule
      ),
  },
  {
    path: "feedbackprevention",
    loadChildren: () =>
      import("./pages/feedbackprevention/feedbackprevention.module").then(
        (x) => x.FeedbackpreventionPageModule
      ),
  },
  {
    path: "feedbacklifestyle",
    loadChildren: () =>
      import("./pages/feedbacklifestyle/feedbacklifestyle.module").then(
        (x) => x.FeedbacklifestylePageModule
      ),
  },
  {
    path: "feedbackseekhelpif",
    loadChildren: () =>
      import("./pages/feedbackseekhelpif/feedbackseekhelpif.module").then(
        (x) => x.FeedbackseekhelpifPageModule
      ),
  },
  {
    path: "feedbackoverview",
    loadChildren: () =>
      import("./pages/feedbackoverview/feedbackoverview.module").then(
        (x) => x.FeedbackoverviewPageModule
      ),
  },
  {
    path: "questionnaire",
    loadChildren: () =>
      import("./pages/questionnaire/questionnaire.module").then(
        (x) => x.QuestionnairePageModule
      ),
  },
  {
    path: "mygp",
    loadChildren: () =>
      import("./pages/mygp/mygp.module").then((x) => x.MygpPageModule),
  },
  {
    path: "feedbackzone",
    loadChildren: () =>
      import("./pages/feedbackzone/feedbackzone.module").then(
        (x) => x.FeedbackzonePageModule
      ),
  },
  {
    path: "painscore",
    loadChildren: () =>
      import("./pages/painscore/painscore.module").then(
        (x) => x.PainscorePageModule
      ),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then(
        (x) => x.SettingsPageModule
      ),
  },
  {
    path: "reinjured",
    loadChildren: () =>
      import("./pages/reinjured/reinjured.module").then(
        (x) => x.ReinjuredPageModule
      ),
  },
  {
    path: "treatmentzone",
    loadChildren: () =>
      import("./pages/treatmentzone/treatmentzone.module").then(
        (x) => x.TreatmentzonePageModule
      ),
  },
  {
    path: "treatmentcategory/:category_id",
    loadChildren: () =>
      import("./pages/treatmentcategory/treatmentcategory.module").then(
        (x) => x.TreatmentcategoryPageModule
      ),
  },
  {
    path: "treatmentcategory/:category_id/:type",
    loadChildren: () =>
      import("./pages/treatmentcategory/treatmentcategory.module").then(
        (x) => x.TreatmentcategoryPageModule
      ),
  },
  {
    path: "treatmentshop/:category_id/:listing_id",
    loadChildren: () =>
      import("./pages/treatmentshop/treatmentshop.module").then(
        (x) => x.TreatmentshopPageModule
      ),
  },
  {
    path: "treatmentshop/:category_id/:listing_id/:type",
    loadChildren: () =>
      import("./pages/treatmentshop/treatmentshop.module").then(
        (x) => x.TreatmentshopPageModule
      ),
  },
  {
    path: "treatmentbuy/:category_id/:listing_id",
    loadChildren: () =>
      import("./pages/treatmentbuy/treatmentbuy.module").then(
        (x) => x.TreatmentbuyPageModule
      ),
  },
  {
    path: "treatmentbuy/:category_id/:listing_id/:type",
    loadChildren: () =>
      import("./pages/treatmentbuy/treatmentbuy.module").then(
        (x) => x.TreatmentbuyPageModule
      ),
  },
  {
    path: "infozone",
    loadChildren: () =>
      import("./pages/infozone/infozone.module").then(
        (x) => x.InfozonePageModule
      ),
  },
  {
    path: "googlesearch",
    loadChildren: () =>
      import("./pages/googlesearch/googlesearch.module").then(
        (x) => x.GooglesearchPageModule
      ),
  },
  {
    path: "comparerecovery",
    loadChildren: () =>
      import("./pages/comparerecovery/comparerecovery.module").then(
        (x) => x.ComparerecoveryPageModule
      ),
  },
  {
    path: "staffmembers",
    loadChildren: () =>
      import("./pages/staffmembers/staffmembers.module").then(
        (x) => x.StaffmembersPageModule
      ),
  },
  {
    path: "staffmember",
    loadChildren: () =>
      import("./pages/staffmember/staffmember.module").then(
        (x) => x.StaffmemberPageModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((x) => x.LoginPageModule),
  },
  {
    path: "register",
    loadChildren: () =>
      import("./pages/register/register.module").then(
        (x) => x.RegisterPageModule
      ),
  },
  {
    path: "accesscodes",
    loadChildren: () =>
      import("./pages/accesscodes/accesscodes.module").then(
        (x) => x.AccesscodesPageModule
      ),
  },
  {
    path: "myproblems",
    loadChildren: () =>
      import("./pages/myproblems/myproblems.module").then(
        (x) => x.MyproblemsPageModule
      ),
  },
  {
    path: "myworries",
    loadChildren: () =>
      import("./pages/myworries/myworries.module").then(
        (x) => x.MyworriesPageModule
      ),
  },
  {
    path: "questionnaires/:id",
    loadChildren: () =>
      import("./pages/questionnaires/questionnaires.module").then(
        (x) => x.QuestionnairesPageModule
      ),
  },
  {
    path: "myrecoverytimeline",
    loadChildren: () =>
      import("./pages/myrecoverytimeline/myrecoverytimeline.module").then(
        (x) => x.MyrecoverytimelinePageModule
      ),
  },
  {
    path: "fakesplash",
    loadChildren: () =>
      import("./pages/fakesplash/fakesplash.module").then(
        (x) => x.FakesplashPageModule
      ),
  },
  {
    path: "programs",
    loadChildren: () =>
      import("./pages/programs/programs.module").then(
        (x) => x.ProgramsPageModule
      ),
  },
  {
    path: "programhome",
    loadChildren: () =>
      import("./pages/programhome/programhome.module").then(
        (x) => x.ProgramhomePageModule
      ),
  },
  {
    path: "programhome/:program_id",
    loadChildren: () =>
      import("./pages/programhome/programhome.module").then(
        (x) => x.ProgramhomePageModule
      ),
  },
  {
    path: "programsection/:program_id/:phase_id",
    loadChildren: () =>
      import("./pages/programsection/programsection.module").then(
        (x) => x.ProgramsectionPageModule
      ),
  },
  {
    path: "recovery",
    loadChildren: () =>
      import("./pages/recovery/recovery.module").then(
        (x) => x.RecoveryPageModule
      ),
  },
  {
    path: "prevention",
    loadChildren: () =>
      import("./pages/prevention/prevention.module").then(
        (x) => x.PreventionPageModule
      ),
  },
  {
    path: "exercises",
    loadChildren: () =>
      import("./pages/exercises/exercises.module").then(
        (x) => x.ExercisesPageModule
      ),
  },
  {
    path: "support",
    loadChildren: () =>
      import("./pages/support/support.module").then((x) => x.SupportPageModule),
  },
  {
    path: "tips",
    loadChildren: () =>
      import("./pages/tips/tips.module").then((x) => x.TipsPageModule),
  },
  {
    path: "introslider",
    loadChildren: () =>
      import("./pages/introslider/introslider.module").then(
        (x) => x.IntrosliderPageModule
      ),
  },
  {
    path: "exercises/:category_id",
    loadChildren: () =>
      import("./pages/exercisescategory/exercisescategory.module").then(
        (x) => x.ExercisescategoryPageModule
      ),
  },
  {
    path: "programonboarding/:program_id",
    loadChildren: () =>
      import("./pages/programonboarding/programonboarding.module").then(
        (x) => x.ProgramonboardingPageModule
      ),
  },
  {
    path: "myappointments",
    loadChildren: () =>
      import("./pages/myappointments/myappointments.module").then(
        (x) => x.MyappointmentsPageModule
      ),
  },
  {
    path: "myappointments/:back",
    loadChildren: () =>
      import("./pages/myappointments/myappointments.module").then(
        (x) => x.MyappointmentsPageModule
      ),
  },
  {
    path: "unlocked",
    loadChildren: () =>
      import("./pages/unlocked/unlocked.module").then(
        (x) => x.UnlockedPageModule
      ),
  },
  {
    path: "diary",
    loadChildren: () =>
      import("./pages/diary/diary.module").then((x) => x.DiaryPageModule),
  },
  {
    path: "diary/:id",
    loadChildren: () =>
      import("./pages/diarycreate/diarycreate.module").then(
        (x) => x.DiarycreatePageModule
      ),
  },
  {
    path: "diarycreate",
    loadChildren: () =>
      import("./pages/diarycreate/diarycreate.module").then(
        (x) => x.DiarycreatePageModule
      ),
  },
  {
    path: "ppolicy",
    loadChildren: () =>
      import("./pages/ppolicy/ppolicy.module").then((x) => x.PpolicyPageModule),
  },
  {
    path: "aims",
    loadChildren: () =>
      import("./pages/aims/aims.module").then((x) => x.AimsPageModule),
  },
  {
    path: "monitorsummary/:type",
    loadChildren: () =>
      import("./pages/monitorsummary/monitorsummary.module").then(
        (x) => x.MonitorsummaryPageModule
      ),
  },
  {
    path: "monitorquestionnaire",
    loadChildren: () =>
      import("./pages/monitorquestionnaire/monitorquestionnaire.module").then(
        (x) => x.MonitorquestionnairePageModule
      ),
  },
  {
    path: "monitorquestions",
    loadChildren: () =>
      import("./pages/monitorquestions/monitorquestions.module").then(
        (x) => x.MonitorquestionsPageModule
      ),
  },
  {
    path: "smpage/:id",
    loadChildren: () =>
      import("./pages/smpage/smpage.module").then((x) => x.SmpagePageModule),
  },
  {
    path: "smpage/:id/:module",
    loadChildren: () =>
      import("./pages/smpage/smpage.module").then((x) => x.SmpagePageModule),
  },
  {
    path: "smquestionnaire/:smblock_id",
    loadChildren: () =>
      import("./pages/smquestionnaire/smquestionnaire.module").then(
        (x) => x.SmquestionnairePageModule
      ),
  },
  {
    path: "smquestionnaire/:smblock_id/:smpage_id/:module_id",
    loadChildren: () =>
      import("./pages/smquestionnaire/smquestionnaire.module").then(
        (x) => x.SmquestionnairePageModule
      ),
  },
  {
    path: "referral-questionnaire/:type",
    loadChildren: () =>
      import(
        "./pages/referral-questionnaire/referral-questionnaire.module"
      ).then((x) => x.ReferralQuestionnairePageModule),
  },
  {
    path: "guest",
    loadChildren: () =>
      import("./pages/guest/guest.module").then((m) => m.GuestPageModule),
  },
  {
    path: "postcode-entry",
    loadChildren: () =>
      import("./pages/postcode-entry/postcode-entry.module").then(
        (m) => m.PostcodeEntryPageModule
      ),
  },
  {
    path: "select-practice",
    loadChildren: () =>
      import("./pages/select-practice/select-practice.module").then(
        (m) => m.SelectPracticePageModule
      ),
  },
  {
    path: "create-account",
    loadChildren: () =>
      import("./pages/create-account/create-account.module").then(
        (m) => m.CreateAccountPageModule
      ),
  },
  {
    path: "need-help",
    loadChildren: () =>
      import("./pages/need-help/need-help.module").then(
        (m) => m.NeedHelpPageModule
      ),
  },
  {
    path: "welcome",
    loadChildren: () =>
      import("./pages/welcome/welcome.module").then((m) => m.WelcomePageModule),
  },
  {
    path: "support-modules",
    loadChildren: () =>
      import("./pages/support-modules/support-modules.module").then(
        (m) => m.SupportModulesPageModule
      ),
  },
  {
    path: "select-area",
    loadChildren: () =>
      import("./pages/select-area/select-area.module").then(
        (m) => m.SelectAreaPageModule
      ),
  },
  {
    path: "need-help-thankyou",
    loadChildren: () =>
      import("./pages/need-help-thankyou/need-help-thankyou.module").then(
        (m) => m.NeedHelpThankyouPageModule
      ),
  },
  {
    path: "local-service-options",
    loadChildren: () =>
      import("./pages/local-service-options/local-service-options.module").then(
        (m) => m.LocalServiceOptionsPageModule
      ),
  },
  {
    path: "pelvic-floor-trainer",
    loadChildren: () =>
      import("./pages/pelvic-floor-trainer/pelvic-floor-trainer.module").then(
        (m) => m.PelvicFloorTrainerPageModule
      ),
  },
  {
    path: "select-super-organisation",
    loadChildren: () =>
      import(
        "./pages/select-super-organisation/select-super-organisation.module"
      ).then((m) => m.SelectSuperOrganisationPageModule),
  },
  {
    path: "nhs-callback",
    loadChildren: () =>
      import("./pages/nhs-callback/nhs-callback.module").then(
        (m) => m.NhsCallbackPageModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/confirm-phone-email/confirm-phone-email.module').then( m => m.ConfirmPhoneEmailPageModule)
  },
  {
    path: 'enter-code',
    loadChildren: () => import('./pages/mfa-code/mfa-code.module').then( m => m.MfaCodePageModule)
  },
  {
    path: 'access-denied',
    loadChildren: () => import('./pages/access-denied/access-denied.module').then( m => m.AccessDeniedPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
