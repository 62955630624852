import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-genericquestionnaire',
  templateUrl: './genericquestionnaire.component.html',
  styleUrls: ['./genericquestionnaire.component.scss'],
})
export class GenericquestionnaireComponent implements OnInit {

  @Input() questionnaire:any;
  @Output() submited = new EventEmitter<any>();;
  public questions:any;

  constructor(public data: DataService, public alertController: AlertController) { 

  }

  ngOnInit() {}

  segmentChanged(event){
    console.log(this)
  }

  submit(){
    let that = this;
    
    if(this.questionnaire.blockquestions && this.questionnaire.blockquestions.length){
      this.questions = this.questionnaire.blockquestions
    } 
    if(this.questionnaire.numericquestions && this.questionnaire.numericquestions.length){
      this.questions = this.questionnaire.numericquestions
    }  

    let answersLeft = this.questions.filter((q) => !q.answer)
    if(answersLeft && answersLeft.length){
      that.presentAlert('Questionnaire warning', '', 'OK', 'Please answer to all questions');
    }else{
      let q_res = that.questions.map(function(q){
        return {
          'id': q.id,
          'answer': q.answer
        }
      });
      that.submited.emit({done: true, type: that.questionnaire.type, id: that.questionnaire.id, questions: q_res})
    }
  }

  setQuestions(questions:any){
    this.questions = questions
  }

  async presentAlert(header:string, subHeader:string, button:string, response:string) {

    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: response,
      buttons: [button]
    });

    await alert.present();
  }

}
