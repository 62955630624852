<div class="replaced-icon" *ngIf="(replace_icon || replace_icon_name) && (text == undefined || text == null || text == '' || data.user.language_id == 0)">
  <ion-icon src="{{replace_icon}}" [hidden]="replace_icon == undefined"></ion-icon>
  <ion-icon class="gub-icon-blue" name="{{replace_icon_name}}" [hidden]="replace_icon_name == undefined"></ion-icon>
</div>
<div class="typing-indicator" *ngIf="(replace_icon == undefined && replace_icon_name == undefined) && (text == undefined || text == null || text == '' || data.user.language_id == 0)">
  <span></span>
  <span></span>
  <span></span>
</div>
<div class="tts-play" (click)="play()" *ngIf="text && data.user.language_id > 0">
  <audio id="tts-audio-player">
    <source src="" type="audio/mpeg" id="tts-audio-source">
      Your browser does not support the audio element.
  </audio>
  <img src="/assets/touch-to-speak/translate_icon_green.svg" class="enabled" [ngClass]="{active: data.user.language_id > 0 && (media_url == '' || data.ttsMediaPlayerSources[media_url] == '')}"/>
  <!--img src="/assets/touch-to-speak/tts-disabled.svg" class="disabled" [ngClass]="{active: data.user.language_id == 0}"/-->
  <span class="tts-play-svg" [ngClass]="{active: data.ttsMediaPlayerSources[media_url] == 'playing'}">
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 392.3 392.3" style="enable-background:new 0 0 392.3 392.3;" xml:space="preserve">
    <style type="text/css">
      .volst0{fill:#8ebc39;}
      .volst1{fill:#FFFFFF;}
    </style>
    <g>
      <path class="volst0" d="M143.7,385.2c-12.9-5.1-25.5-9.7-37.8-15.1c-4.9-2.1-9.1-2.8-14.1-1c-18.9,6.8-37.8,13.4-56.7,20
        c-2.4,0.9-5,1.4-8.3,2.4c1.4-8.1,2.6-15.4,4-22.7c2.9-15.4,5.9-30.7,8.8-46.1c0.9-4.6-0.1-8.6-3-12.5c-19.3-26.2-31-55.6-34.9-87.9
        c-8.1-67.5,13-124.7,62.4-171.1c28.4-26.7,62.5-42.6,101-48.7c98.5-15.7,193.2,44.7,219.5,140.9c17.3,63.3,5.5,121.7-34.6,173.4
        c-31.1,40.1-72.5,64.5-122.7,72.9C199.3,394.5,171.4,392.7,143.7,385.2 M207.4,147.6c0-15,0-29.9,0-44.9c0-4.1-1.5-7.4-5.4-9.5
        c-4.1-2.1-7.7-1.2-11.2,1.4c-1.1,0.8-2.2,1.7-3.2,2.6c-19.3,16.8-38.7,33.5-58,50.3c-4,3.5-8.4,5.2-13.8,5.1
        c-17.1-0.2-34.2-0.1-51.3-0.1c-10.4,0-13.4,3.1-13.4,13.6c0,23.6,0,47.2,0,70.8c0,10.2,3.4,13.5,13.6,13.6c17,0,33.9,0.2,50.9,0.1
        c5.3,0,9.4,1.9,13.3,5.2c14.4,12.6,28.9,25,43.4,37.5c5.8,5,11.7,10,17.5,15.1c3.5,3,7.3,4.5,11.9,2.4c4.6-2.1,5.8-6,5.8-10.6
        L207.4,147.6 M323.7,146.2c-5.7-16-13.6-30.9-23.9-44.5c-2.1-2.8-4.6-5.5-7.5-7.4c-5.5-3.5-13.6,0.6-13.8,7c-0.1,3,1.4,6.2,2.9,9
        c2.6,4.7,6.1,8.9,8.8,13.5c33.1,55.7,30.5,110.3-4.9,164c-2,3.1-4.2,6.1-6,9.3c-2.3,4.4-1.1,9.3,2.8,12.4c3.2,2.6,7.7,2.6,11.5-0.4
        c2-1.6,3.9-3.5,5.5-5.6C334,255.5,342,203.3,323.7,146.2 M259.3,144c17.7,29.8,21,61.5,10.1,94.2c-3.5,10.5-9.7,20.1-15.2,29.8
        c-4.4,7.7-1.9,15.6,6.1,16.8c5.4,0.8,8.6-2.5,11.3-6.5c23.3-35.1,29.9-72.8,18.5-113.6c-4.1-14.8-10.8-28.4-20-40.7
        c-3.9-5.2-9.4-6.1-14.2-2.6c-4.6,3.3-5.5,9-2,14.3C255.5,138.4,257.2,140.9,259.3,144 M222.7,247.2c1.1,4.6,3.6,7.9,8.6,8.4
        s8.4-2,11-6.2c7.8-12.5,11.3-26.4,13-40.9c2.3-19.9-3-37.8-13.5-54.3c-3.4-5.3-7.6-6.9-12.5-5.1c-6.8,2.5-8.3,9-3.8,16.9
        c13.6,24.1,13.8,48.2,0,72.4C224.1,240.8,223.6,243.8,222.7,247.2L222.7,247.2L222.7,247.2z"/>
      <path class="volst1" d="M207.4,148.1v152.3c0,4.6-1.2,8.5-5.8,10.6c-4.5,2.1-8.4,0.6-11.9-2.4c-5.8-5-11.7-10-17.5-15.1
        c-14.5-12.5-29-24.9-43.4-37.5c-3.9-3.4-8-5.3-13.3-5.2c-17,0.1-33.9,0-50.9-0.1c-10.2,0-13.6-3.4-13.6-13.6c0-23.6,0-47.2,0-70.8
        c0-10.5,3-13.6,13.4-13.6c17.1-0.1,34.2-0.1,51.3,0.1c5.4,0.1,9.8-1.6,13.8-5.1c19.3-16.8,38.6-33.6,58-50.3
        c1.1-0.9,2.1-1.8,3.2-2.6c3.4-2.6,7-3.6,11.2-1.4c3.9,2,5.4,5.3,5.4,9.5C207.4,117.7,207.4,132.7,207.4,148.1L207.4,148.1
        L207.4,148.1z"/>
      <rect x="213.6" y="83.5" class="volst0" width="128.7" height="235.4"/>
      <path class="volst1 vol-3" d="M323.8,146.6c18.1,56.7,10.2,108.9-24.6,156.9c-1.5,2.1-3.4,4-5.5,5.6c-3.8,3-8.3,3-11.5,0.4
        c-3.8-3.1-5.1-8-2.8-12.4c1.7-3.2,3.9-6.2,6-9.3c35.3-53.7,38-108.3,4.9-164c-2.8-4.6-6.2-8.9-8.8-13.5c-1.5-2.7-3-6-2.9-9
        c0.2-6.4,8.4-10.4,13.8-7c2.9,1.8,5.4,4.6,7.5,7.4C310,115.3,317.9,130.2,323.8,146.6L323.8,146.6L323.8,146.6z"/>
      <path class="volst1 vol-2" d="M259.1,143.8c-1.9-2.9-3.6-5.4-5.2-8c-3.4-5.3-2.6-11,2-14.3c4.7-3.4,10.2-2.6,14.2,2.6
        c9.2,12.3,15.9,25.9,20,40.7c11.4,40.7,4.8,78.5-18.5,113.5c-2.7,4-5.9,7.3-11.3,6.5c-8-1.2-10.5-9.1-6.1-16.8
        c5.5-9.7,11.8-19.3,15.2-29.8C280.3,205.5,277,173.9,259.1,143.8L259.1,143.8L259.1,143.8z"/>
      <path class="volst1 vol-1" d="M222.7,246.9c0.9-3.1,1.4-6,2.8-8.5c13.8-24.2,13.6-48.2,0-72.4c-4.5-7.9-3-14.4,3.8-16.9
        c4.9-1.8,9.1-0.2,12.5,5.1c10.6,16.6,15.8,34.4,13.5,54.3c-1.7,14.5-5.2,28.4-13,40.9c-2.6,4.2-6,6.7-11,6.2
        C226.3,255.2,223.9,251.8,222.7,246.9L222.7,246.9L222.7,246.9z"/>
    </g>
    </svg>
  </span>
  <img src="/assets/touch-to-speak/translate_icon_pause.svg" class="pause" [ngClass]="{active: data.ttsMediaPlayerSources[media_url] == 'pause'}"/>
</div>