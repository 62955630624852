<ion-header class="gub-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <!-- <ion-menu-button (click)='closeMenu()'></ion-menu-button> -->
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card class="no-shadow black-text">
        <h1 class="title-button" expand="full" color="success">Thank you</h1>  

        <div>
          <div class="ion-padding big-thumbs icon-box gub-italic home-box message-box" >
            <app-messagebubble></app-messagebubble>
            <p class="pre_wrap" [innerHTML]="response.output.response"></p>
          </div>
        </div>
        <ion-row *ngIf="response.output.action">
            <!-- <ion-col size="12">
                <ion-label>Have these sympthom resolved:</ion-label>
            </ion-col> -->
            <ion-col *ngIf="response.output.action.code == 'phone' && validPhone() " class="ion-text-center" size="12">
                <ion-button (click)="call()" [disabled]="button_disabled" size="large" color="success">
                  <ion-icon name="call"></ion-icon>
                </ion-button>
            </ion-col>
            <ion-col *ngIf="response.output.action.page" class="ion-text-center" size="12">
                <ion-button (click)='page()' [disabled]="button_disabled" expand="block" color="primary" [innerHTML]="response.output.action.name"></ion-button>
            </ion-col>

            <ion-col *ngIf="response.output.action.showinbox" class="ion-text-center" size="12">
                <ion-button (click)="gotoinbox()" [disabled]="button_disabled" expand="block" color="primary">Inbox</ion-button>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!response.output.action || (response.output.action  && response.output.action.code == '')">
          <ion-col class="ion-text-center" size="12">
            <ion-button (click)='ok()' [disabled]="button_disabled" expand="block" color="success">OK</ion-button>
          </ion-col>
        </ion-row>
    </ion-card>
</ion-content>